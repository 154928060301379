import styled from '@emotion/styled'
import { IconSeparator } from 'app/components/Common/IconSeparator'
import { Email, Phone, Pin } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  bookingEmail?: string
  infoEmail?: string
  languageCode: string
  managementEmail?: string
  phone?: string
  siteName?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  bookingEmail,
  infoEmail,
  languageCode,
  managementEmail,
  phone,
  siteName,
}: Props) {
  return (
    <Container>
      <IconSeparator className="separator" />

      {SiteName ? <SiteName>{siteName}</SiteName> : null}
      <Wrapper dial={2} row wrap>
        {address ? (
          <Item>
            <Pin />
            <Label>{useVocabularyData('address', languageCode)}</Label>
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          </Item>
        ) : null}
        {phone ? (
          <Item>
            <Phone />
            <Label>{useVocabularyData('telephone', languageCode)}</Label>
            <Anchor
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })
                }
              }}
            >
              {phone}
            </Anchor>
          </Item>
        ) : null}
        {infoEmail || managementEmail ? (
          <Item>
            <Email />
            <Label>{useVocabularyData('info', languageCode)}</Label>
            {infoEmail ? (
              <Anchor
                href={`mailto:${infoEmail}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {infoEmail}
              </Anchor>
            ) : null}
            {managementEmail ? (
              <>
                <Divider />
                <Anchor
                  href={`mailto:${managementEmail}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  {managementEmail}
                </Anchor>
              </>
            ) : null}
          </Item>
        ) : null}
        {bookingEmail ? (
          <Item>
            <Email />
            <Label>{useVocabularyData('booking', languageCode)}</Label>
            <Anchor
              href={`mailto:${bookingEmail}`}
              onClick={() => {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'Click', {
                    event_category: 'Website',
                    event_label: 'Email Address',
                  })
              }}
            >
              {bookingEmail}
            </Anchor>
          </Item>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 7.5rem 12.639vw;
  text-align: center;

  .separator {
    margin-bottom: 1.75rem;
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark2};
    }
  }
  @media (max-width: 1199px) {
    padding: 7.5rem 5vw;
  }

  @media (max-width: 1023px) {
    padding: 5rem 3.75rem 3.75rem;

    .separator {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding: 3.75rem 1.25rem 3rem;
  }
`

const SiteName = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.6875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 67.25rem;
  margin: 3.75rem auto 0;
`

const Item = styled.div`
  width: 25%;
  text-align: left;

  svg {
    width: auto;
    height: 1.875rem;
    fill: none;
    margin-bottom: 1.5rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark2};
    stroke-width: 2;
  }

  @media (max-width: 1023px) {
    width: 50%;
    margin-bottom: 1.875rem;
    text-align: center;
  }

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    svg {
      display: none;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  margin-bottom: 0.625rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
  @media (max-width: 424px) {
    margin-bottom: 0.375rem;
    line-height: 1;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: -0.0313rem;
  line-height: 1.5rem;

  @media (max-width: 767px) {
    font-size: 0.8125rem;
  }
  @media (max-width: 424px) {
    line-height: 1.25rem;
  }
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    font-size: 0.8125rem;
  }
  @media (max-width: 424px) {
    line-height: 1.25rem;
  }
`

const Divider = styled.div``
