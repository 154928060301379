import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Separator } from 'app/components/Common/Separator'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  languageCode: string
  languagePrefix?: string | null
  onContactsFormSendToBackend: ContactsFormSenderBackend
  title?: string
}

export const ContactsForm = memo(function ContactsForm({
  description,
  languageCode,
  onContactsFormSendToBackend,
  title,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Contacts Form',
          })
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      offer_title:
        typeof window !== 'undefined'
          ? new URLSearchParams(window.location.search).get('offer') ||
            undefined
          : undefined,
      room_title:
        typeof window !== 'undefined'
          ? new URLSearchParams(window.location.search).get('room') || undefined
          : undefined,
    },
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container>
      <Separator />
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Wrapper row wrap space="between">
          <Field>
            <Label>{`${useVocabularyData('name', languageCode)}*`}</Label>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('lastname', languageCode)}*`}</Label>
            <Input
              error={formState.errors.lastname !== undefined}
              isDirty={formState.dirtyFields.lastname}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('email', languageCode)}*`}</Label>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Label>{`${useVocabularyData('telephone', languageCode)}*`}</Label>
            <Input
              error={formState.errors.phone !== undefined}
              isDirty={formState.dirtyFields.phone}
              type="text"
              required={true}
              {...register('phone', {
                required: true,
                validate: (phone) => phone.length > 2,
              })}
            />
          </Field>
          <Field className="large-field distance-field">
            <Label>{useVocabularyData('message', languageCode)}</Label>
            <Textarea required={false} {...register('message')} />
          </Field>
          <Input type="hidden" {...register('offer_title')} />
          <Input type="hidden" {...register('room_title')} />
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('form-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Wrapper>
        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}
        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}
        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('send-request', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled.section`
  margin: 9rem 0 7.5rem;
  padding: 0 12.639vw;
  position: relative;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 0 5vw;
  }

  @media (max-width: 1023px) {
    margin: 4.5rem 0;
    padding: 0 1.25rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`

const Title = styled.h3`
  max-width: 40rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.375rem;
  margin: auto;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  max-width: 40rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
  margin: 2.5rem auto 0;
`

const Form = styled.form`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 4.75rem auto 0;
  padding: 2.75rem 13.958vw 5.4375rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 1023px) {
    padding: 2.5rem 3.75rem 3.75rem;
  }

  @media (max-width: 767px) {
    padding: 1.25rem 1.25rem 3rem;
  }
`

const Wrapper = styled(FlexBox)`
  text-align: left;
`

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 2.5rem;
  &.large-field {
    width: 100%;
  }
  &.distance-field {
    margin-top: 3.25rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.875rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  text-transform: uppercase;
`
